import Axios, { AxiosResponse } from "axios";

/* Utils */
import { getCookie } from "@utils/cookie";

/* Consts */
import { COOKIE_KEYS } from "@src/consts";

/* Types */
import type { RequestType } from "./types";

const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export const AxiosWrapper = async <T, K = Record<string, string>>(
  req: RequestType<K>,
): Promise<T> => {
  const {
    body,
    defaultHeaders,
    headers,
    isProtectedRoute,
    method,
    url,
    params,
    baseURL,
    withCredentials,
  } = req;

  let { token, refreshToken } = req;

  return new Promise<T>(async (resolve, reject) => {
    let finalHeaders = headers || {};

    if (isProtectedRoute) {
      token = token || (getCookie(COOKIE_KEYS.AUTH_TOKEN) as string);
      refreshToken =
        refreshToken || (getCookie(COOKIE_KEYS.REFRESH_TOKEN) as string);
      finalHeaders = {
        ...finalHeaders,
        Authorization: `Bearer ${token}`,
        "x-refresh-token": refreshToken,
      };
    }

    if (defaultHeaders)
      finalHeaders = {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      };

    let searchParams = new URLSearchParams();
    if (params)
      Object.keys(params).forEach((key) => {
        searchParams.append(key, params[key] as string);
      });

    const urlWithParams = `${url}?${searchParams.toString()}`;

    const data: void | AxiosResponse<T, K> = await axios({
      method: method.toLowerCase(),
      headers: finalHeaders,
      data: body,
      url: urlWithParams,
      baseURL,
      withCredentials,
    }).catch((err) => {
      reject(err.response);
    });

    if (data) resolve(data.data);
  });
};

/* Cookies should be present as every route is being checked by the middleware for authentication. 
   If not present then it could be any of the following reasons:
    1. The route is not in the middleware matcher list.
    2. The backend could've wrongly implemneted auth on this particular route.

 */
