"use client";

import React, { useCallback, useMemo } from "react";
import TsParticles from "react-particles";
import { loadSlim } from "tsparticles-slim";

/* Consts */
import { ParticleOpts } from "@src/consts";

/* Types */
import type { Engine } from "tsparticles-engine";

type Props = {
  children?: React.ReactNode;
  count?: number;
};

export const Particles: React.FC<Props> = (props) => {
  const { count = 300 } = props;

  const particlesInit = useCallback(async (engine: Engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container: any) => {}, []);

  /* Memos */
  const opts = useMemo(() => {
    ParticleOpts.particles.number.value = count;
    return ParticleOpts;
  }, [count]);

  return (
    <TsParticles
      id="ts-particles"
      options={opts}
      init={particlesInit}
      loaded={particlesLoaded}
    />
  );
};
