"use client";
import React, { useMemo } from "react";

/* Components */
import PopularCoursesSection from "./component";
import { Heading, Text, SuspenseSkeleton, CircleLight } from "@components";
import { useHomeData } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
};

const HomePopularCoursesSection: React.FC<Props> = (props) => {
  const {} = props;

  /* Memos */
  const [query] = useHomeData();

  return (
    <div className="relative overflow-x-clip">
      <CircleLight intensity={80} left="105%" top="40%" size="15rem" />
      <CircleLight intensity={75} left="25%" top="40%" size="5rem" />
      <div className="flex flex-col gap-12 container">
        <div className="text-center lg:text-left flex flex-col items-center w-full gap-2">
          <Heading weight={700} size="3xl">
            Most
            <Text size="3xl" as="span" weight={700} color="gradient">
              {" Popular "}
            </Text>
            Courses
          </Heading>
          <Text>
            Explore from {query.data.data?.courseCount} online Courses
            {/*  in {categories} categories */}
          </Text>
        </div>
        <SuspenseSkeleton
          count={5}
          width="6.25em"
          height="6.25em"
          containerClassName="flex justify-around"
        >
          <PopularCoursesSection />
        </SuspenseSkeleton>
        <div className="grid place-items-center">
          {/* <Button
            className="!px-12"
            color="gradient"
            variant="outline"
            size="lg"
          >
            View Categories
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default HomePopularCoursesSection;
