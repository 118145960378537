import React from "react";
import Image from "next/image";

/* CSS */
import { astronuatClass, flyingSaucerClass, rocketClass } from "./styles.css";

type Props = {
  children?: React.ReactNode;
};

export const Astronaut: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <div className={astronuatClass}>
      <Image
        className="absolute top-1/4 -translate-y-2/3 right-0"
        alt="Astronuat in space"
        src="/home/astronaut.png"
        width={167}
        height={223}
      />
    </div>
  );
};

export const FlyingSaucer: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <div className={flyingSaucerClass}>
      <Image
        className="absolute top-0 left-0"
        alt="Rocket Ship"
        src="/home/flyingSaucer.png"
        width={281}
        height={281}
      />
    </div>
  );
};

export const Rocket: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <div className={rocketClass}>
      <Image
        className="absolute top-0 right-0"
        alt="Rocket Ship"
        src="/home/rocket.png"
        width={281}
        height={281}
      />
    </div>
  );
};
