"use client";

import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";

/* Components */
import { Card, Heading, Text, Button } from "@components";

/* Hooks */
import { useUniqueId, useWindowSize } from "@src/hooks";

/* Utils */
import { FormatDate, parseISO } from "@utils/dates-fns";

/* Services */
import { useHomeData } from "@src/service/home";

/* Types */
import type { HomePageArticles } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
};

const DUMMY_CONTENT = [
  {
    id: 1,
    author: "Utkarsh",
    publishDate: new Date(),
    imageUrl: "/home/background.png",
    title: "Do quis laborum consectetur anim a enim. ",
  },
  {
    id: 2,
    author: "Abhishek",
    publishDate: new Date(),
    imageUrl: "/home/background.png",
    title: "Do quis laborum consectetur anim ",
  },
  {
    id: 3,
    author: "John Doe",
    publishDate: new Date(),
    imageUrl: "/home/background.png",
    title: "Do quis laborum consectetur anim  Nulla do nulla enim. ",
  },
  {
    id: 4,
    author: "John Doe",
    publishDate: new Date(),
    imageUrl: "/home/background.png",
    title: "Do quis laborum consectetur anim  Nulla do nulla enim. ",
  },
];

const HomeArticles: React.FC<Props> = (props) => {
  const {} = props;

  const articles = useMemo(() => DUMMY_CONTENT, []);

  /* State */
  const [data, setData] = useState<HomePageArticles[]>([]);

  /* Service */
  const [query] = useHomeData();

  /* Effects */
  useEffect(() => {
    if (query.isFetched && query.data.data) setData(query.data.data.articles);
  }, [query.data.data, query.isFetched, setData]);

  /* Hooks */
  const { bp, sizes } = useWindowSize();

  /* Keys */
  const cardsKey = useUniqueId("home-articles-card-");

  return (
    <div className="container mt-12 flex flex-col gap-12">
      <div className="flex flex-col items-center gap-4 justify-between sm:flex-row sm:items-start">
        <Heading weight={700} size="2xl" className="text-center">
          Our
          <Text as="span" weight={700} color="gradient">
            {" Articles"}
          </Text>
        </Heading>
        <Button variant="outline" color="gradient" size="xl">
          Read More Articles
        </Button>
      </div>
      <div className="grid grid-rows-3 md:grid-rows-1 md:grid-cols-3 gap-4">
        {data.map((article, i) => (
          <Card
            useGrid={false}
            className={`grid !gap-4 min-h-[12rem] ${
              sizes.width > bp.md && i === 0
                ? "col-span-3 grid-cols-[3fr_1fr]"
                : "grid-rows-2"
            }`}
            key={cardsKey + i}
          >
            <div className="flex flex-col gap-3">
              <Heading size="sm" weight={700} color="purple">
                {FormatDate(parseISO(article.publishDate), "MMMM dd, yyyy")}
              </Heading>
              <Heading size="lg" weight={700} className="mb-4">
                {article.title}
              </Heading>
              <Text>By {article.author}</Text>
            </div>
            <div className="flex w-full relative h-full">
              <Image
                src={article.imageUrl}
                alt="Article Image"
                className="object-cover"
                fill
              />
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HomeArticles;
