import { AxiosWrapper } from "../axios";
import { ENDPOINTS } from "../endpoints";

import type { HomeDataResponse } from "./types";

export const getHomeData = async (): Promise<HomeDataResponse> => {
  return await AxiosWrapper({
    method: "GET",
    url: ENDPOINTS.home,
    defaultHeaders: true,
  });
};
