import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/articles/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/businessOfferings/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/courses/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/hero/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/keyConcepts/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/popularCourses/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/practice/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/pricing/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/recentProjects/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/testimonials/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/home/videoSection/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/layouts/particles.tsx")