"use client";

import Image from "next/image";
import React, { useEffect, useState } from "react";

/* Components */
import { cardContainerStyles } from "./styled.css";
import {
  Card,
  CircleLight,
  Heading,
  SuspenseSkeleton,
  Text,
} from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";
import { Button } from "@components/Button";

/* Services */
import { type HomePageProjects, useHomeData } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
};

const HomeRecentProjects: React.FC<Props> = (props) => {
  const {} = props;

  /* State */
  const [data, setData] = useState<HomePageProjects[]>([]);

  /* Service */
  const [query] = useHomeData();

  /* Effects */
  useEffect(() => {
    if (query.isFetched && query.data.data) setData(query.data.data.projects);
  }, [query.data.data, query.isFetched, setData]);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className="overflow-hidden relative">
      <CircleLight intensity={50} size="5rem" top="50%" />
      <CircleLight intensity={50} size="5rem" left="90%" top="50%" />
      <div className="container mt-12 flex flex-col gap-12">
        <Heading weight={700} size="3xl" className="text-center">
          Our Recent
          <Text as="span" weight={700} color="gradient">
            {" Projects"}
          </Text>
        </Heading>
        <div className={cardContainerStyles}>
          <SuspenseSkeleton width="100%" height="50%">
            {data.map((item, i) => (
              <Card layout="horizontal" key={cardsKey + i}>
                <div className="relative overflow-hidden rounded-xl w-full h-32">
                  <Image
                    priority={false}
                    src={item.imageUrl}
                    fill
                    alt="Image"
                    className="object-cover"
                  />
                </div>
                <div className="flex flex-col gap-4 mt-4 pb-4">
                  <Heading weight={700}>{item.title}</Heading>
                  <Text className="text-ellipsis overflow-hidden line-clamp-3">
                    {item.description}
                  </Text>
                </div>
              </Card>
            ))}
          </SuspenseSkeleton>
        </div>
        <div className="flex justify-center">
          <Button variant="outline" color="gradient" size="xl">
            View All Projects
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomeRecentProjects;
