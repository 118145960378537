"use client";

import Image from "next/image";
import React, { useMemo } from "react";

/* Components */
import { Card, Heading, Text } from "@components";

/* CSS */
import { cardContainerStyles, courseContainerStyles } from "./styled.css";

/* Hooks */
import { useUniqueId, useWindowSize } from "@src/hooks";

type Props = {
  children?: React.ReactNode;
};

const DUMMY_CONTENT = [
  {
    id: 1,
    title: "Array and its types",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. ",
  },
  {
    id: 2,
    title: "Linked List",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in.",
  },
  {
    id: 3,
    title: "Stack",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. ",
  },
  {
    id: 4,
    title: "Queue and its types",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim. o quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim.",
  },
  {
    id: 5,
    title: "Queue and its types",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim. o quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim.",
  },
  {
    id: 6,
    title: "Queue and its types",
    imageUrl: "/home/bg.png",
    description:
      "Do quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim. o quis laborum consectetur anim proident occaecat Lorem laboris in. Nulla do nulla enim. Nulla do nulla enim.",
  },
];

const HomeCourses: React.FC<Props> = (props) => {
  const {} = props;

  const { bp, sizes } = useWindowSize();

  const courses = useMemo(() => DUMMY_CONTENT, []);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className={`relative overflow-hidden mb-12 ${courseContainerStyles}`}>
      <div className="container mt-12 flex flex-col gap-12">
        <Heading weight={700} size="3xl" className="text-center" color="white">
          Courses of{" "}
          <Text size="3xl" as="span" weight={700} color="gradient">
            Cloud and ML & AI
          </Text>
        </Heading>
        <div className={cardContainerStyles}>
          {courses.map((item, i) => (
            <Card key={cardsKey + i}>
              <div className="relative w-full min-h-32">
                <Image
                  priority={false}
                  src={item.imageUrl}
                  fill
                  alt="Image"
                  className="object-cover"
                />
              </div>
              <div className="flex flex-col gap-4 mt-4 pb-4">
                <Heading
                  color={sizes.width > bp.md ? "black" : "white"}
                  weight={700}
                >
                  {item.title}
                </Heading>
                <Text
                  color={sizes.width > bp.md ? "black" : "white"}
                  className="text-ellipsis overflow-hidden line-clamp-3"
                >
                  {item.description}
                </Text>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCourses;
