"use client";

import Image from "next/image";
import React, { useMemo } from "react";

/* Components */
import { Card, Heading, Text } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";
import { Button } from "@components/Button";

type Props = {
  children?: React.ReactNode;
};

const DUMMY_CONTENT = [
  {
    id: 2,
    title: "Read",
    imageUrl: "/home/book-open.png",
    description: "SED Sheet - A Complete Guide",
  },
  {
    id: 3,
    title: "Practice",
    imageUrl: "/home/brain.png",
    description: "SED Sheet - Learn for Learn",
  },
  {
    id: 4,
    title: "Learn",
    imageUrl: "/home/microphone.png",
    description: "Interview Preparation",
  },
];

const HomePracticeSection: React.FC<Props> = (props) => {
  const {} = props;

  const courses = useMemo(() => DUMMY_CONTENT, []);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className="container my-12 lg:my-24 p-6 bg-neutral-gray flex flex-col gap-12 border-gradient">
      <div className="grid grid-rows-3 sm:grid-rows-1 sm:grid-cols-3 justify-evenly">
        {courses.map((item, i) => (
          <div
            className="flex sm:justify-center gap-4 w-full items-center"
            key={cardsKey + i}
          >
            <div className="grid place-items-center bg-neutral-gray rounded-lg p-4">
              <Image
                src={item.imageUrl}
                width={32}
                height={32}
                alt="Image"
                className="object-cover"
              />
            </div>
            <div className="flex flex-col gap-2 mt-4 pb-4">
              <Heading color="gradient" weight={700}>
                {item.title}
              </Heading>
              <Text>{item.description}</Text>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomePracticeSection;
