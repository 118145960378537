"use client";

import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";

/* Components */
import { cardContainerStyles } from "./styled.css";
import { Card, Heading, SuspenseSkeleton, Text } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";
import { Button } from "@components/Button";

/* Services */
import { useHomeData } from "@src/service/home";

/* Types */
import type { HomePageTestimonials } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
};

const HomeTestimonials: React.FC<Props> = (props) => {
  const {} = props;

  /* State */
  const [data, setData] = useState<HomePageTestimonials[]>([]);

  /* Service */
  const [query] = useHomeData();

  /* Effects */
  useEffect(() => {
    if (query.isFetched && query.data.data)
      setData(query.data.data.testimonials);
  }, [query.data.data, query.isFetched, setData]);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className="container mt-12 flex flex-col gap-12">
      <Heading weight={700} size="3xl" className="text-center">
        What people are saying
        <Text size="3xl" as="span" weight={700} color="gradient">
          {" about us"}
        </Text>
      </Heading>
      <div className={cardContainerStyles}>
        <SuspenseSkeleton width="100%" height="50%">
          {data.map((item, i) => (
            <Card className="!px-4 !pt-4" key={cardsKey + i}>
              <Text className="text-ellipsis overflow-hidden line-clamp-3">
                {item.message}
              </Text>
              <div className="grid grid-cols-[1fr_2fr] gap-4">
                <div className="overflow-hidden grid place-items-center h-full w-full">
                  <Image
                    src={item.imageUrl}
                    className="rounded-full"
                    width={48}
                    height={48}
                    alt="Image"
                  />
                </div>
                <div className="flex flex-col gap-2">
                  <Heading size="base" weight={700}>
                    {item.user}
                  </Heading>
                  <Text color="purple">{item.position}</Text>
                </div>
              </div>
            </Card>
          ))}
        </SuspenseSkeleton>
      </div>
      <div className="flex justify-center">
        <Button variant="outline" color="gradient" size="xl">
          View All
        </Button>
      </div>
    </div>
  );
};

export default HomeTestimonials;
