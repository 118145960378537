import type { FormatRelativeLocale } from "./types";

type Locales = "weekly";

export const LOCALES: Record<Locales, FormatRelativeLocale> = {
  weekly: {
    lastWeek: "eeee",
    yesterday: "'Yesterday'",
    tomorrow: "'Tomorrow'",
    today: "'Today'",
    nextWeek: "'Next' eeee",
    other: "dd/MM/yyyy",
  },
};
