"use client";
import Link from "next/link";
import Image from "next/image";
import React, { useEffect, useState } from "react";
import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

/* Components */
import { Button } from "@components/Button";
import { Heading, Text, SuspenseSkeleton, CircleLight } from "@components";

/* Services */
import { useHomeData } from "@src/service/home";

/* Types */
import type { HomePageKeyConcepts } from "@src/service/getHeader";

type Props = {
  children?: React.ReactNode;
};

const HomeKeyConceptSection: React.FC<Props> = (props) => {
  const {} = props;

  const [query] = useHomeData();

  const [data, setData] = useState<HomePageKeyConcepts | null>(null);

  useEffect(() => {
    if (query.isFetched && query.isSuccess && query.data.data)
      setData(query.data.data.keyConcepts[0]);
  }, [query.data, query.isFetched, query.isSuccess]);

  return (
    <div className="overflow-hidden relative">
      <CircleLight top="50%" left="70%" size="12rem" intensity={100} />
      <CircleLight top="50%" left="20%" size="8rem" intensity={75} />
      <div className="container flex lg:flex-row flex-col-reverse items-center">
        <div className="flex flex-col lg:w-1/2 w-full gap-6">
          <div className="lg:text-left text-center">
            <Heading weight={700} size="2xl">
              Important Key Concepts
              <Text size="2xl" as="span" weight={700} color="gradient">
                {" Concepts "}
              </Text>
              &
              <Text size="2xl" as="span" weight={700} color="gradient">
                {" Terminologies "}
              </Text>
            </Heading>
          </div>
          <div>
            <SuspenseSkeleton width="100%" height={50}>
              <Text color="blue" textCase="uppercase" size="lg">
                {data?.subheading}
              </Text>
            </SuspenseSkeleton>
          </div>
          <div>
            <SuspenseSkeleton width="100%" height={100}>
              <Text>{data?.description}</Text>
            </SuspenseSkeleton>
          </div>
          {/* <Button
            variant="outline"
            color="gradient"
            size="xl"
            rightIcon={() => <ArrowUpRightIcon width={18} height={18} />}
          >
            <Link href={data?.hrefUrl || "#"}>Read More</Link>
          </Button> */}
        </div>
        <div>
          {data?.imageUrl && (
            <Image
              alt="An astronuat with a basketball"
              src={data.imageUrl}
              width={820}
              height={830}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeKeyConceptSection;
