"use client";

import React, { useEffect, useMemo, useState } from "react";

/* Components */
import { PricingCard } from "./card";
import { cardContainerStyles } from "./styled.css";
import { CircleLight, Heading, Text } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* Services */
import { useHomeData } from "@src/service/home";

/* Types */
import type { HomePagePricing } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
};

const HomePricingSection: React.FC<Props> = (props) => {
  const {} = props;

  /* State */
  const [data, setData] = useState<HomePagePricing[]>([]);

  /* Service */
  const [query] = useHomeData();

  /* Effects */
  useEffect(() => {
    if (query.isFetched && query.data.data) setData(query.data.data.pricing);
  }, [query.data.data, query.isFetched, setData]);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className="overflow-hidden relative">
      <CircleLight top="50%" left="20%" size="8rem" intensity={75} />
      <CircleLight top="75%" left="90%" size="5rem" intensity={75} />
      <div className="container mt-12 flex flex-col gap-12">
        <div className="flex flex-col text-center gap-4">
          <Heading weight={700} size="3xl" className="text-center">
            Pricing
          </Heading>
          <Text>
            We build readymade websites, mobile applications, and elaborate
            online business services.
          </Text>
        </div>
        <div className={cardContainerStyles}>
          {data.map((item, i) => (
            <PricingCard item={item} key={cardsKey + i} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePricingSection;
