export enum COURSE_KEYS {
  LIST = "list-courses",
  DETAIL = "get-course-details",
  LIST_BY_RANK = "list-by-rank",
  SEARCH = "search-courses",
}

export enum HEADER_KEYS {
  GET_HEADER = "get-headers",
}
