import React from "react";
import Link from "next/link";
import Image from "next/image";

/* Components */
import { Text } from "@components";
import { logoContainerStyles } from "./styled.css";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* APIs */
import { useListCourseByRank } from "@src/service/course";

/* Types */
import type { ListByRankType } from "@src/service/course";

type Props = {
  children?: React.ReactNode;
};

const PopularCoursesSection: React.FC<Props> = (props) => {
  const {} = props;

  /* APIs */
  const [query] = useListCourseByRank(false);

  /* Keys */
  const mapKey = useUniqueId("hero-course-image-");

  return (
    <div className={logoContainerStyles}>
      {query.data.data!.slice(0, 6).map((item, i) => (
        <RenderItem key={mapKey + i} data={item} />
      ))}
    </div>
  );
};

const RenderItem: React.FC<{ data: ListByRankType }> = ({ data }) => {
  return (
    <Link
      href={`/course/${data.slug}`}
      className="relative grid grid-rows-[3fr_1fr] gap-4"
    >
      <Image
        className="object-center object-contain justify-self-center"
        width={100}
        height={100}
        src={data.thumbnailUrl}
        alt="Icons"
      />
      <Text className="text-center">{data.name}</Text>
    </Link>
  );
};

export default PopularCoursesSection;
