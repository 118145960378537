"use client";

import Image from "next/image";
import React, { useEffect, useMemo, useState } from "react";

/* Components */
import { cardContainerStyles } from "./styled.css";
import {
  Card,
  Heading,
  Text,
  SuspenseSkeleton,
  CircleLight,
} from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* Services */
import { type HomePageOfferings, useHomeData } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
};

const BusinessOfferings: React.FC<Props> = (props) => {
  const {} = props;

  /* State */
  const [data, setData] = useState<HomePageOfferings[]>([]);

  /* Service */
  const [query] = useHomeData();

  /* Effects */
  useEffect(() => {
    if (query.isFetched && query.data.data) setData(query.data.data.offerings);
  }, [query.data.data, query.isFetched, setData]);

  /* Keys */
  const cardsKey = useUniqueId("home-course-card-");

  return (
    <div className="overflow-hidden relative">
      <CircleLight top="50%" left="20%" size="8rem" intensity={75} />
      <CircleLight top="60%" left="80%" size="5rem" intensity={75} />
      <div className="container mt-12 flex flex-col gap-12">
        <div className="flex flex-col text-center gap-4">
          <Heading weight={700} size="3xl" className="text-center">
            How can we help your
            <Text size="3xl" as="span" weight={700} color="gradient">
              {" Business "}
            </Text>
            ?
          </Heading>
          <Text>
            We build readymade websites, mobile applications, and elaborate
            online business services.
          </Text>
        </div>
        <div className={cardContainerStyles}>
          <SuspenseSkeleton>
            {data.map((item, i) => (
              <Card
                className="!rounded-3xl !py-12"
                rows="1fr 2fr"
                key={cardsKey + i}
              >
                <div className="grid h-full place-items-center">
                  <div className="grid place-items-center bg-neutral-gray rounded-lg p-4">
                    <Image
                      src={item.imageUrl}
                      width={32}
                      height={32}
                      alt="Image"
                      className="object-cover"
                    />
                  </div>
                </div>
                <div className="flex flex-col text-center gap-4 mt-4 pb-4">
                  <Heading weight={700}>{item.title}</Heading>
                  <Text
                    size="sm"
                    className="text-ellipsis overflow-hidden line-clamp-3"
                  >
                    {item.description}
                  </Text>
                </div>
              </Card>
            ))}
          </SuspenseSkeleton>
        </div>
      </div>
    </div>
  );
};

export default BusinessOfferings;
