import { useSuspenseQuery } from "@tanstack/react-query";

/* API */
import { getHomeData } from "./handler";

/* Types */
import type { HomeDataResponse } from "./types";
import type { ResponseType } from "../types";

export const useHomeData = () => {
  const query = useSuspenseQuery<HomeDataResponse, ResponseType>({
    queryKey: ["home-data"],
    queryFn: getHomeData,
  });

  return [query];
};
