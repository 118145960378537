import React from "react";
import Image from "next/image";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

/* Components */
import { Card, Heading, Text, Button } from "@components";

/* Hooks */
import { useUniqueId } from "@src/hooks";

/* Types */
import type { PricingType } from "./types";
import type { HomePagePricing } from "@src/service/home";

type Props = {
  children?: React.ReactNode;
  item: HomePagePricing;
};

type HeaderProps = Pick<
  PricingType,
  "imageUrl" | "title" | "description" | "subscription"
>;

type ContentProps = Pick<PricingType, "price" | "features" | "hrefUrl">;

export const PricingCard: React.FC<Props> = (props) => {
  const { item } = props;

  return (
    <Card
      className="!rounded-3xl !py-6 !px-3 !gap-0 hover:border-gradient"
      rows="1fr 2fr"
      style={{ "--border-gradient-radii": "1.5rem" }}
    >
      <Header
        title={item.title}
        imageUrl={item.imageUrl}
        description={item.description}
        subscription={item.subscription}
      />
      <Content
        hrefUrl={item.hrefUrl}
        price={item.price}
        features={item.features}
      />
    </Card>
  );
};

const Header: React.FC<HeaderProps> = ({
  imageUrl,
  title,
  subscription,
  description,
}) => (
  <div className="flex flex-col gap-4">
    <div className="h-full grid items-center gap-4 grid-cols-[1fr_3fr]">
      <div className="grid place-items-center bg-neutral-gray rounded-2xl p-3">
        <Image
          src={imageUrl}
          width={48}
          height={48}
          alt="Image"
          className="object-cover"
        />
      </div>
      <div className="flex flex-col">
        <Text size="xs">{title}</Text>
        <Heading size="lg" weight={700}>
          {subscription}
        </Heading>
      </div>
    </div>
    <div className="flex flex-col gap-4 mt-4 pb-4">
      <Text size="sm" className="text-ellipsis overflow-hidden line-clamp-3">
        {description}
      </Text>
    </div>
  </div>
);

const Content: React.FC<ContentProps> = (props) => {
  const { price, features, hrefUrl } = props;

  /* Keys */
  const featureKey = useUniqueId("pricing-card-");

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Heading className="inline" weight={700} size="3xl" color="gradient">
          ${price}
        </Heading>
        <Text className="pt-4" size="base" color="primary" as="span">
          /monthly
        </Text>
      </div>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <Heading weight={500} size="lg">
            {"What's included"}
          </Heading>
          {features.map((feature, i) => (
            <div className="flex gap-2" key={featureKey + i}>
              <CheckCircleIcon
                className="stroke-primary"
                height={24}
                width={24}
                fontSize={24}
              />
              <Text key={featureKey + i}>{feature}</Text>
            </div>
          ))}
        </div>
      </div>
      <div className="flex mt-2 justify-self-end h-full justify-center items-end">
        <Button
          href={hrefUrl}
          size="lg"
          as="a"
          variant="outline"
          color="gradient"
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};
