"use client";
import React from "react";
import Image from "next/image";
import { ArrowUpRightIcon } from "@heroicons/react/20/solid";

/* Components */
import { Heading, Text } from "@components";
import { Button } from "@components/Button";

type Props = {
  children?: React.ReactNode;
};

const HomeVideoSection: React.FC<Props> = (props) => {
  const {} = props;
  return (
    <div className="container grid lg:grid-rows-1 lg:grid-cols-2 grid-rows-2 items-center">
      <div>
        <Image
          alt="An astronuat with a basketball"
          src="/home/keyConceptImage.png"
          width={820}
          height={830}
        />
      </div>
      <div className="flex flex-col gap-6">
        <div></div>
        <div>
          <Heading weight={700} size="2xl">
            Take the first step to
            <Text size="2xl" as="span" weight={700} color="gradient">
              {" knowledge "}
            </Text>
            {"with Moss Tech"}
          </Heading>
        </div>
        <Text>
          Do quis laborum consectetur anim proident occaecat Lorem laboris in.
          Laborum esse nulla dolore nostrud sunt adipisicing fugiat. Ex
          incididunt velit dolor anim consequat ipsum.
        </Text>
      </div>
    </div>
  );
};

export default HomeVideoSection;
